.greenBackground {
  background-color: #3c4424;
}

.primaryBackground {
  background-color: #f6e6d9;
}

.header-nav li {
  position: relative;
}
.header-nav li::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: #3c422e;
  opacity: 0;
  transition: all ease 0.3s;
}
.header-nav li:hover::before {
  opacity: 1;
  bottom: 10%;
}

.header-section {
  height: 7rem;
}

.header-section.sticky {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 90;
  box-shadow: 4px 4px 14px rgb(60, 66, 46, 0.1);
  transition: all ease 0.3s;
  height: 6rem;
}
.logo {
  transition: width ease 0.4s;
}
.header-section.sticky .logo {
  width: 6.4rem;
  padding-top: 0;
}
.header-section.sticky .navigation {
  height: 4rem;
}
@media (min-width: 992px) {
  .header-section.sticky .logo {
    width: 7.8rem;
  }
  .header-section.sticky .navigation {
    height: 4.2rem;
  }
}
