#gallery-slider-container {
  height: 18rem;
  margin-top: 4rem;
  width: 95%;
  margin: auto;
  display: grid;
  place-items: center;
  overflow: hidden;
}
#gallery-container {
  width: calc(300px * 16);
  display: flex;
  min-height: 18rem;
  animation: scroll 30s linear infinite;
}
.image-slide {
  height: 18rem;
  width: 18rem;
  display: flex;
  align-items: center;
  perspective: 100px;
}
.gallery-image {
  width: 100%;
  height: 90%;
  object-fit: cover;
  border-radius: 11px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-300px * 8));
  }
}
