.table-header {
  font-size: 2.8rem;
  font-style: italic;
  color: #9eef80;
  text-shadow: 0.04em 0.005em #3c422e, 0.5px 0.5px #3c422e,
    -0.25px -0.25px #3c422e, 0.25px 0.25px #3c422e, 1px 1px #3c422e;
}

.bg-gardient {
  background-image: radial-gradient(
    50% 50% at 50% 50%,
    #d1e030 0,
    transparent 100%
  );

  width: 100%;
  height: 100%;
  top: 0px;
}

.box-class {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px dashed #3c422e;
  flex-direction: column;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1;
  color: #3c422e;
  flex-shrink: 0;
  height: 6rem;
  width: 14rem;
  padding-inline: 1.4rem;
}
.box-class.first-col {
  width: 20rem;
}
.box-class.box-class-image {
  height: 8rem;
}

.colm img {
  width: 3.2rem;
}
.colm.sama img {
  width: 30%;
  /* object-fit: contain; */
}

p [name="close-outline"],
p [name="checkmark-outline"] {
  font-size: 2.6rem;
  color: #3c422e;
}

.border-left {
  border-left: 1px dashed black;
}
.border-style {
  border-left: 1px dashed;
  border-right: 1px dashed;
}

@media (min-width: 768px) {
  .table-header {
    font-size: 3rem;
  }
}

@media (min-width: 992px) {
  .colm-table {
    overflow: hidden;
  }
  .scroll-text {
    visibility: hidden;
  }
  .box-class {
    width: 100%;
  }
  .box-class.first-col {
    width: 100%;
  }

  .colm {
    width: calc(70% / 4);
  }
}
