@tailwind base;
@tailwind components;
@tailwind utilities;

/*  Video settings */
video::-webkit-media-controls {
  display: none;
}

*::-webkit-media-controls-panel {
  display: none !important;

  -webkit-appearance: none;
}

*::-webkit-media-controls-play-button {
  display: none !important;

  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;

  -webkit-appearance: none;
}

@font-face {
  font-family: "zarid light";
  src: url(../src/assests/font/29LTZaridSansAL-Light.otf) format("woff");
  font-display: swap;
  fallback-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: "zarid";
  src: url(../src/assests/font/29LTZaridSans-Rg.otf) format("woff");
  font-display: swap;
  fallback-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

@font-face {
  font-family: "siri regular";
  src: url(../src/assests/font/elmessiri-regular.otf);
  font-weight: 400;
  font-display: swap;
  fallback-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
@font-face {
  font-family: "siri medium";
  src: url(../src/assests/font/elmessiri-medium.otf);
  font-weight: 600;
  font-display: swap;
  fallback-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
@font-face {
  font-family: "siri bold";
  src: url(../src/assests/font/elmessiri-semibold.otf);
  font-weight: 800;
  font-display: swap;
  fallback-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Radial backgournd */
.radial-background {
  background-image: radial-gradient(50% 50%, #99d574 0, #0000 100%);
}

/* Recipes card */
.box:hover h1 {
  text-decoration: underline;
}
.word-spacing {
  word-spacing: 6px;
}

/* grid */
.recipes-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 2rem;
}

@media (min-width: 768px) {
  .recipes-grid {
    grid-template-columns: 1.2fr 2fr;
  }
}
@media (min-width: 992px) {
  .recipes-grid {
    grid-template-columns: 1fr 2fr;
  }
}

input:-internal-autofill-selected {
  background-color: yellow !important;
}
