.button {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
  text-transform: capitalize;
  font-family: "zarid regular";
  z-index: 4;
  transition: all linear 0.4s;
}

.button::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;

  border: 1px solid #3c422e;
  background-image: linear-gradient(45deg, #3c422e 25%, transparent 25%),
    linear-gradient(-45deg, #3c422e 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #3c422e 75%),
    linear-gradient(-45deg, transparent 75%, #3c422e 75%);

  background-position: 0 0, 0 3px, 3px -3px, -3px 0;
  background-size: 6px 6px;
  transform: translate(5px, 5px);

  height: 100%;
  width: 100%;
  z-index: 0;
  transition: transform linear 0.3s;

  border-radius: 0.25rem;
}

.button::before {
  content: attr(data-content);
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 3;
  border: 1px solid #3c422e;
  background-color: #99d574;
  transition: transform linear 0.3s;
  padding-inline: 1rem;
  border-radius: 0.25rem;
}

.button:hover::before {
  transform: translate(5px, 5px);
}
