.Container {
  width: min(100% - 2rem, 120rem);
  margin-inline: auto;
}

.inner-container {
  width: min(100% - 2.6rem, 120rem);
  margin-inline: auto;
}

@media (min-width: 768px) {
  .Container {
    width: min(100% - 2.6rem, 120rem);
  }
}

@media (min-width: 992px) {
  .inner-container {
    width: 75%;
    margin-inline: 0;
    margin-left: auto;
    padding-right: 1rem;
  }
}
