@media (min-width: 992px) {
  .olive-image {
    transform: translateX(-200%);
    opacity: 0;
    transition: all ease 0.3s;
  }

  .process-box:hover .olive-image {
    transform: translateX(0%);
    opacity: 1;
  }

  .bottle-image {
    transform: translateY(100%);
    opacity: 0;
    user-select: none;
    visibility: hidden;
    transition: transform ease 0.3s;
    /* object-fit: cover; */
  }

  .process-box:hover .bottle-image {
    transform: translateY(0%);
    opacity: 1;
    visibility: visible;
  }
}
